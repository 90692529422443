#home--container {
    width: 80%; 
    height: 100%;
    justify-content: center;
    max-width: 1500px;
}

#homeintro--container {
    background-color: grey;
    font-weight: bold;
    height: 90vh;
    width: 100%;
    position: absolute;
    top: 0%;
    z-index: -1;
    padding-top: 50px;
    background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/home/home-image-1920.webp");
    background-size: cover;
    background-position: right top;
    justify-content: center;
}

#homeintro-text {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    text-align: center;
    margin-top: -100px;
}

#homeintro-text h1 {
    /* letter-spacing: 0.05rem; */
    /* font-family: 'Open Sans'; */
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 3rem;
    font-weight: 200;
    width: 100%;
    padding: 30px;
}

#homeintro-text p {
    font-weight: 200;
    margin-bottom: 0px;
}

#majors--container {
    width: 100%;
}

#majors-elements {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 30px;
    column-gap: 30px;
    margin-top: 65vh; 
    width: 100%;
    margin-bottom: 50px;
}

#show-all-p {
    margin-left: 7px;
    font-size: 1.3rem;
    font-weight: 500;
}


/* HOW IT WORKS */
#how-it-works--container {
    margin: 60px 0;
    width: 100%;
}

#how-it-works--title {
    width: 30%;
    margin-bottom: 200px;
}

#how-it-works--all-info {
    flex-grow: 1;
    position: relative;
}

#how-it-works--title > h1,
#review-title {
    font-weight: 500;
    font-size: 2.5rem;
}

#how-it-works--title > h1,
#majors-home--title {
    width: 80%;
}

#how-it-works--img {
    height: 560px;
    background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/home/home-subimage.webp");
    background-size: cover;
    background-position: center;
    width: 320px;
    border-radius: 30px;
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.25);
}

#how-it-works--info {
    flex-grow: 1;
    height: 90%;
    justify-content: space-evenly;
    align-items: flex-start !important;
    position: absolute;
    left: 270px;
    z-index: 10;
}

.how-it-works--list {
    width: 100%;
    align-items: flex-start;
}

.number-list {
    color: var(--green-600);
    background-color: white;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 800;
    margin-right: 20px;
    box-shadow: 0px 20px 40px 0 rgba(0, 0, 0, 0.1);
}

.paragraph-list {
    text-wrap: wrap;
    flex: 1;
}

.paragraph-list > h3 {
    font-weight: 400;
    font-size: 1.4rem;
}

.paragraph-list > p {
    /* text-align: justify; */
    color: rgba(10, 10, 10, 0.5);
}


/* BENEFITS */
.home-icon--container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2.15px solid var(--green-600);
    height: 50px;
    width: 50px;
}

#benefits--container {
    width: 100%;
}

#benefits--sections {
    width: 98%;
    justify-content: space-between;
    align-items: flex-start !important;
    margin: 36px 0px;
}

.benefits--section {
    padding: 10px;
    align-items: flex-start !important;
    width: 20%;
}

.benefits--subtitle {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.benefits--subtitle h3 {
    font-size: 1.6rem;
    font-weight: 300;
    margin: 12px 0 15px 0;
}

.benefits--section > p {
    font-size: 1.1rem;
    color: rgba(10, 10, 10, 0.5);
    font-weight: 400;
    /* text-align: justify; */
    margin: 0;
    line-height: 1.5rem;
}

.full-width-height {
    width: 100vw;
    height: 100vh;
}

.element-info-h2 {
    font-size: 1.4rem;
}

#homeintro-text p {
    font-size: 1.25rem;
}

.next-prev--carousel .small-box {
    width: 250px;
    height: 195px;
    opacity: 0.7;
}

.majors-carousel--container {
    display: none;
}

.majors-carousel--container::-webkit-scrollbar {
    display: none; 
}

.next-prev-majors {
    opacity: 0.7;
    position: absolute;
    width: 270px;
    box-shadow: none !important;
}

.prev-majors {
    left: -110px;
}

.next-majors {
    right: -110px;
}

#majors-carousel--bg {
    width: 100vw;
    overflow: hidden;
}

.prev-next-major--carousel {
    height: 195px !important;
    opacity: 0.6;
    border-color: rgba(0,0,0,0.15) !important;
}

/* RESPONSIVENESS */
/* 
Mobile Devices:

Small Mobile (Portrait): 320px - 480px
Large Mobile (Portrait): 481px - 767px
Tablets:

Small Tablets (Portrait): 768px - 1024px
Large Tablets (Landscape): 1025px - 1279px
Laptops and Desktops:

Small Laptops: 1280px - 1366px
Large Laptops and Desktops: 1367px and above
Extra-Large Screens:

Extra-Large Desktops: 1920px and above
*/

@media (max-width: 1280px) {
    #homeintro--container {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/home/home-image-1280.webp");
    }
}

@media (max-width: 1200px) {
    #majors-elements {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}

/* Small mobile (portrait): 320px - 480px */
@media (max-width: 1024px) {
    #homeintro--container {
        padding-top: 80px !important;
    }

    #homeintro-text {
        width: 70% !important;
    }

    #home--container {
        width: 87% !important;
    }

    #how-it-works--title {
        width: 24%;
    }

    #how-it-works--img {
        width: 280px !important;
    }

    #how-it-works--info {
        left: 225px !important;
    }
}

@media (max-width: 768px) {
    #homeintro--container {
        height: 50vh !important;
        min-height: 330px;
        position: static !important;
        margin: 0 !important;
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/home/home-image-768.webp");
    }

    #homeintro-text {
        margin-top: 5px !important;
        width: 100% !important;
    }

    .home-icon--container {
        margin-right: 15px;
        min-width: 35px;
        max-width: 35px;
        height: 35px;
    }

    #homeintro-text h1 {
        font-size: 2.4rem !important;
    }

    #homeintro-text p {
        margin: 15px 0 0 0 !important;
    }

    #majors--container {
        display: none !important;
    }

    .majors-carousel--container {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 60px;
        overflow-x: scroll;
        width: 100%;
    }

    .majors-carousel--container .small-box:hover {
        background-color: white !important;
    }

    .majors-carousel--container .small-box {
        border: 1px rgba(0,0,0,0.08) solid;
        transition: height 0.5s ease;
        width: 270px;
        box-shadow: none;
    }
    
    .majors-carousel--container a:first-child {
        margin-left: calc((100vw - 220px) / 2);
    }
    
    .majors-carousel--container a:last-child {
        margin-right: calc((100vw - 220px) / 2);
    }

    #majors-elements {
        margin-top: 0 !important;
        display: flex !important;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-bottom: 5px;
        scrollbar-width: none; 
        -ms-overflow-style: none;
    }

    #majors-elements::-webkit-scrollbar {
        display: none;
    }

    #home--container {
        width: 88%;
        margin-top: 30px;
    }

    #how-it-works--title {
        width: 100%;
        margin-bottom: 30px;
    }

    #how-it-works--info {
        left: 0;
        position: static;
    }

    #how-it-works--container {
        flex-direction: column !important;
        margin: 50px;
    }

    #how-it-works--container div {
        width: 100%;
    }

    .number-list {
        width: 70px;
        height: 70px;
        font-size: 2.3rem;
    }

    .paragraph-list {
        margin-top: 10px;
    }

    #benefits--sections {
        flex-direction: column !important;
    }

    .benefits--section {
        width: 100%;
        margin-bottom: 10px;
    }

    .benefits--subtitle {
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .benefits--subtitle h3 {
        font-size: 1.5rem;
    }

    .home-icon {
        margin-right: 10px;
        margin-bottom: 5px;
    }

    #reviews--container {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    }

    .review-box {
        width: fit-content;
        box-shadow: none;
        margin-right: 20px;
    }

    .review-box--review {
        width: 250px;
    }

    #how-it-works--title > h1,
    #majors-home--title,
    #review-title {
        font-weight: 300;
        font-size: 1.8rem;
        width: 100%;
    }

    #majors-home--title {
        margin-bottom: 20px;
    }

    #majors--container .small-button {
        padding: 8px 0;
        min-width: 140px;
        max-width: 140px;
        align-self: center;
        height: fit-content;
    }

    .carousel,
    .carousel .small-box {
        width: 270px; /* the box inside the carousel */
        box-shadow: none;
    }

    .carousel .small-box {
        border: 2px var(--grey-300-transparent-2) solid
    }
}

@media (max-width: 550px) {
    .majors-carousel--container {
        column-gap: 40px;
    }

    .majors-carousel--container .small-box {
        width: 220px;
    }

    .majors-carousel--container a:first-child {
        margin-left: calc((100vw - 220px) / 2);
    }
    
    .majors-carousel--container a:last-child {
        margin-right: calc((100vw - 220px) / 2);
    }
}

@media (max-width: 400px) {
    #home--container {
        width: 90% !important;
    }

    #homeintro--container {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/home/home-image-400.webp");
    }    
}

@media (max-width: 440px) {
    .carousel,
    .carousel .small-box {
        width: 230px; /* the box inside the carousel */
        box-shadow: none;
    }

    .majors-carousel--container {
        column-gap: 20px;
    }
}

@media (max-width: 352px) {
    #homeintro--container {
        height: 57vh !important;
    }
}