.faq--container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 30px 25px;
    align-items: center;
    /* border: 2px var(--green-600) solid; */
    border-left: 7px var(--green-600) solid;
    border-radius: 5px;
    margin-top: 90px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    position: relative;
    z-index: 10;
    background-color: white;
}

.faq-title {
    color: var(--green-600);
    letter-spacing: 1px;
    margin-bottom: -5px;
    font-size: 1.8rem;
    font-weight: 400;
}

.faq-title--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
    width: min-content;
    align-self: flex-start;
}

.question-icon--faq {
    font-size: 8rem;
    color: var(--green-600);
    font-weight: 200;
}

.accordion-flush {
    flex-grow: 1;
}

.accordion-button:not(.collapsed) {
    background-color: rgba(0, 172, 151, 0.5);
    color: black;
}

.accordion {
    --bs-accordion-btn-focus-border-color: rgba(0, 172, 151, 0.5);
    --bs-accordion-active-color: var(--green-600);
    --bs-accordion-btn-color: black;
    --bs-accordion-btn-icon: url("../images/arrow-down.png");
    --bs-accordion-btn-active-icon: url("../images/arrow-down.png");
    --bs-accordion-btn-focus-box-shadow: 0 rgba(0, 172, 151, 0.1);
}


@media (max-width: 1150px) {
    .faq-title {
        font-size: 1.6rem;
    }
}

@media (max-width: 1024px) {
    .faq--container {
        flex-direction: column;
        padding-right: 20px;
        padding-left: 20px;
    }

    .accordion {
        width: 100%;
    }

    .faq-title {
        margin-bottom: 0;
    }
    
    .faq-title--container {
        width: fit-content;
        margin-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .faq--container {
        border-top: 7px var(--green-600) solid;
        border-left: none;
        padding-right: 0;
        padding-left: 0;
        margin-top: 50px;
    }

    .faq-title {
        margin-left: 15px;
    }
}

@media (max-width: 550px) {
    .faq-title {
        font-size: 1.4rem;
    }
}