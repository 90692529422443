/* REVEAL ON SCROLL */
.opacity-1 {
    opacity: 1;
    transform: translateY(0);
}

.opacity-0 {
    opacity: 0;
    transform: translateY(30px);
}

.transition-opacity-transform {
    transition: opacity 0.8s, transform 0.8s;
}


/* SLIDE RIGHT/LEFT */
.out-of-frame--right {
    transform: translateX(-250px) skewX(-20deg);
}

.out-of-frame--left {
    transform: translateX(250px) skewX(20deg);
}

.transition-transform {
    transition: transform 1s ease-in-out;
}


/* SLIDE DIAGONAL */
.slide-diagonal {
    transform: translateX(-40px) translateY(-15px);
}

.transition-transform-600 {
    transition: transform 0.9s ease-in-out;
}
