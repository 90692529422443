#tutor-courses--container,
#all-bookings--container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 100px;
}

#tutor-courses--content,
#all-bookings--content {
    align-self: center;
    padding: 30px;
    margin-top: 88px;
}

#tutor-courses--content {
    width: 70%;
    margin-left: 30%;
}

.back-button-tutors {
    top: 0%;
    left: 0%;
}

#tutor-course-box {
    background-color: white;
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 285px;
    width: 250px;
    justify-content: center;
}

#tutor-courses {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 30px;
    margin-top: 50px;
}

#tutor-courses--search {
    height: auto;
    align-self: center;
    margin: 0;
}

.small-grey {
    font-size: 1.5rem;
    color: grey;
    align-self: center;
}

.tutor-info--tutorcourses {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

/* .tutor-courses--title {
    height: 100px;
} */

#tutor-info,
#all-bookings--calendar {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 35px 50px 35px;
    overflow-y: scroll;
    overflow-x: hidden;
    bottom: 0;
    top: 79px;
}

#tutor-info {
    width: 30%;
}

#tutor-info::-webkit-scrollbar-track,
#all-bookings--calendar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
}

#tutor-info::-webkit-scrollbar,
#all-bookings--calendar::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

#tutor-info::-webkit-scrollbar-thumb,
#all-bookings--calendar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--green-600);
}

#tutor-courses--box,
#all-bookings--box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.margin-less {
    margin: 0 !important;
}

.tutor-courses-rating--container {
    align-items: flex-end !important;
}

.tutor-courses-rating--container p {
    font-size: 1.2rem;
    margin-bottom: -2px;
}

.stars--container-tutor-courses .material-symbols-outlined {
    font-size: 1.7rem;
}

.tutor-courses--titles {
    margin: 0 !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#tutor-courses--profile {
    margin-bottom: 10px;
}

#star-rating--container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#rating-text {
    /* margin-top: 7px; */
    margin-bottom: 20px;
    cursor: pointer;
}

#rating-text:hover {
    color: var(--grey-600);
}

#rating-hr {
    height: 1.45px;
    background-color: rgba(125, 124, 124, 0.7);
    width: 70%;
    border-radius: 10px;
    border: none;
    margin: 0;
    margin-bottom: 20px;
}

.star-rating:hover {
    cursor: pointer;
}

.star-rating {
    font-weight: 300;
    font-size: 2rem; 
    margin: 0;
}

#tutor-profile {
    font-size: 13rem;
    font-weight: 100;
    color: var(--green-600);
}

#rating-change {
    position: fixed;
    bottom: 7%;
    left: 4%;
    color: white;
    padding: 15px 30px 15px 30px;
    background-color: var(--green-600);
    border-radius: 5px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

#rating-change > span {
    margin-right: 10px;
}

.margin-top {
    margin-top: 45px;
}

.email-link {
    text-decoration: underline;
}

.tutor-courses--titles .email-link {
    color: var(--green-600); 
}

.profile-picture-img--tutor-courses {
    margin-top: 30px;
    margin-bottom: 20px;
}

@media (max-width: 1280px) {
    #tutor-courses {
        grid-template-columns: 1fr 1fr;
    }

    #tutor-courses--search {
        width: 80%;
    }

    #tutor-info {
        padding-top: 0;
        width: 45%;
    }

    #tutor-courses--content {
        width: 55%;
        margin-left: 45%;
    }
}

@media (max-width: 1024px) {
    #tutor-info {
        width: 40%;
    }

    #tutor-courses--content {
        width: 60%;
        margin-left: 40%;
    }
}

@media (max-width: 1024px) {
    #tutor-courses {
        column-gap: 15px;
        row-gap: 15px;
    }
    
    #tutor-course-box {
        width: 230px;
    }
}

@media (max-width: 900px) {
    #tutor-courses--box,
    #all-bookings--box {
        flex-direction: column;
    }

    #tutor-courses--container,
    #tutor-courses--title,
    #all-bookings--container,
    #title-back {
        width: 100vw;
    }

    #tutor-info,
    #all-bookings--calendar {
        position: static;
        padding: 0;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 88px;
    }

    #tutor-info::-webkit-scrollbar,
    #all-bookings--calendar::-webkit-scrollbar {
        width: 0;
    }

    #tutor-courses--title {
        padding: 15px;
        height: fit-content;
    }

    #tutor-courses--box,
    #all-bookings--box {
        width: 90%;
        margin: auto;
    }

    #tutor-courses--content,
    #all-bookings--content {
        margin-left: 0;
        width: 100%;
        margin-top: 50px;
        padding: 0;
    }

    #tutor-courses {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #tutor-course-box {
        flex-direction: row;
        width: 100%;
        height: 80px;
        padding: 10px;
    }

    #tutor-course-box .small-button {
        width: fit-content;
        padding: 9px 12px 9px 12px;
        border-radius: 15px;
    }

    #tutor-course-box .course-title p {
        font-weight: 300;
    }

    #tutor-course-box .course-title {
        margin: 0;
    }

    #tutor-courses--search {
        width: 90% !important;
    }

    #tutor-course-box .green-p-box,
    #tutor-course-box .small-button {
        margin: 0;
        font-size: 1.1rem;
    }

    #tutor-course-box .course-title p {
        font-size: 1.6rem;
    }
}

@media (max-width: 390px) {
    #tutor-course-box .course-title p {
      font-size: 1rem !important;
      margin: 0 5px 0 5px;
      letter-spacing: 0.4px;
    }

    #tutor-course-box .green-p-box, 
    #tutor-course-box .small-button {
        font-size: 0.8rem;
    }

    #tutor-courses--search {
        width: 100% !important;
    }
  }