:root {
    --icons-green: rgb(0, 172, 151);
    --words-Grey: rgba(97, 97, 97, 0.48);
}

/* Reset default margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Container */
#sign-up--container {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
}

.sign-in--container {
    justify-content: center;
    height: 100vh;
}

.contact-section {
    width: 100%;
    padding: 30px 45px;
    animation: opacity 1s ease-in forwards;
    animation-delay: 0.5s;
    opacity: 0;
}

.sign-up--bg {
    margin: 80px 0;
}

.sign-in-up--background {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 60%;
    max-width: 1000px;
    min-width: 500px;
    animation: scroll-down 1s ease-in-out;
}

.phone-number-note {
    font-style: italic;
    padding: 5px 2px 0 2px;
    width: 100%;
    color: var(--red-600);
    letter-spacing: 0.5px;
}

.send-new-code-p {
    color: var(--green-600);
    margin-top: -10px;
}

.verification-input--container {
    width: 100%;
    max-width: 330px;
    border: none;
}

.verification-input--container input:focus {
    border: none;
}

.verification-input--character {
    border-radius: 5px;
    line-height: 40px;
    border: 2px rgba(128, 128, 128, 0.35) solid;
}

.verification-input-char--inactive {
    background-color: transparent;
}

.verification-input-char--selected {
    outline: none;
    border: 2.3px var(--green-600) solid;
    color: rgb(48, 48, 48); 
}

.verification-input--char-filled {
    font-size: 2rem;
    line-height: 50px;
    color: rgb(48, 48, 48); 
}

.verification-code--container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 20px 0;
    align-items: center;
}

.resend-otp--button {
    color: var(--green-600);
    border: none;
    background-color: transparent;
    font-weight: 500;
}

.resend-otp--button:hover {
    color: var(--green-800);
}

.resend-login {
    font-weight: 700;
    cursor: pointer;
    color: var(--red-600);
}

.resend-login:hover {
    color: var(--red-800);
}

.sign-up--profile-button-container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 10px;
}

.agreement--container-sign-up input {
    margin-top: 5px;
}

.agreement--container-sign-up {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;
}


/* MAIL CONFIRMATION*/
.email-confirmation--background {
    background-color: rgba(0, 172, 151, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: var(--regular-font-family);
    padding: 40px;
    box-sizing: border-box;
    margin: 0;
}

.email-confirmation--container {
    text-align: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 30px 40px;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
    max-width: 650px;
}

.email-confirmation--icon {
    margin-bottom: 10px;
}

.email-confirmation--link {
    color: var(--green-600);
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.email-confirmation--link:disabled {
    color: var(--green-800);
    cursor: auto;
}

.resend-email--container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}


@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scroll-down {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 100vh;
    }
}

/* Contact header styles */
.contact-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-header h2 {
    font-size: 2.2rem;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

/* Contact info styles */
.contact-info p {
    font-size: 1.1rem;
    color: black;
    margin-bottom: 30px;
}

.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.label {
    display: block;
    font-size: 16px;
    color: var(--words-Grey);
    margin-bottom: 10px;
    font-weight: bold;
}

.sign-up--elements {
    width: 100%;
    height: 50px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
    transition: border-color 0.3s ease;
}

input:focus,
select:focus,
textarea:focus {
    border: 2px var(--icons-green) solid;
    outline: none;
}

/* Submit button styles */
.submit-button {
    width: 100%;
    height: 50px;
    background-color: var(--icons-green);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: var(--green-800);
}

#description {
    height: 100px;
    margin: 0;
}

#name-sign-up--container {
    justify-content: space-between;
}

.sign-up--text {
    width: 49%;
    margin-bottom: 0;
    margin-top: 0;
}

#sign-in-up-switch {
    align-items: center;
}

.submit--sign-in #sign-in-up-switch {
    margin-top: 7px;
}

#sign-in-up-switch a,
#sign-in-up-switch p {
    margin: 0;
}

#sign-in-up-switch p {
    margin-right: 5px;
}

#sign-in-up-switch a {
    color: var(--icons-green);
}

.form-container hr {
    margin-bottom: 40px;
    margin-top: 30px;
}

#languages-options--signup {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

input[type="checkbox"] {
    cursor: pointer;
    accent-color: var(--icons-green);
    margin-right: 5px;
}

.courses--signup {
    background-color: transparent;
    width: 100%;
    cursor: pointer;
    height: 100px;
}

.courses--signup:focus {
    border: 2px var(--icons-green) solid;
}

@keyframes slideIn {
    from {
        top: -5%;
    }

    to {
        top: 7%;
    }
}

@keyframes slideOut {
    from {
        top: 7%;
    }

    to {
        top: -5%;
    }
}

#form-message {
    position: fixed;
    top: 7%; 
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    width: 60%;
    background-color: var(--icons-green);
    text-align: center;
    font-size: 1rem;
    color: white;
    font-weight: 500;
    box-shadow:  3px 3px 11px rgba(0, 0, 0, 0.2);
    animation: slideIn 1s ease-in, slideOut 1s ease-out 3s;
    padding: 15px 20px 15px 20px;
    z-index: 100;
}


#courses-options {
    height: 150px;
    overflow-y: scroll;
    background-color: rgb(248, 247, 247);
    margin-bottom: 10px;
}

#courses-options div {
    padding: 7px;
}

#selected-courses--container,
#courses-options,
#search-course--form,
#search-bar-container--form {
    border-radius: 5px;
    background-color: rgb(248, 247, 247);
}

#courses-options {
    border-top-left-radius: 0;
}

#selected-courses--container {
    width: 100%;
    margin-bottom: 13px;
    padding: 10px;
    display: inline-flex;
    height: fit-content;
    flex-wrap: wrap;
    min-height: 45px;
    max-height: 280px;
    overflow-y: scroll;
}

#selected-courses--container::-webkit-scrollbar {
    width: 4px;
}
  
#selected-courses--container::-webkit-scrollbar-track {
    background-color: #CDF1EC;
    border-radius: 4px;
}
  
#selected-courses--container::-webkit-scrollbar-thumb {
    background: var(--green-600);
    border-radius: 4px;
}

.date-and-time-note--sign-up {
    margin-top: 10px;
    font-size: 1rem;
    font-style: italic;
    color: var(--grey-300);
}

#course-option--container {
    border-radius: 8px;
    padding: 5px 10px 5px 14px;
    color: white;
    background-color: var(--icons-green);
    width: fit-content;
    margin: 5px;
}

#course-option--container p {
    margin: 0;
    margin-right: 5px;
}

#courses-options div {
    height: fit-content;
}

#search-course--form {
    flex: 1;
    border: none;
}

#search-bar-container--form {
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    /* border-bottom: 3px white solid; */
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    color: rgba(0, 0, 0, 0.54);
}

.delete-course-button:hover {
    cursor: pointer;
}

.settings-white-bg {
    background-color: white !important;
}

.settings-borders {
    border: 1px #cccccc solid !important;
    border-radius: 5px !important;
}

.error-borders {
    border: 2px var(--red-600) solid !important;
    box-shadow: 1px 1px 1px rgba(228, 60, 60, 0.1);
}

.required-asterix {
    color: var(--red-600);
    margin-left: 6px;
}

.error-p {
    color: var(--red-600);
    margin-top: 5px;
    font-weight: 500;
}

.error-div {
    border: 2px var(--red-600) solid;
    border-radius: 5px;
    padding: 10px;
}

.forgot-password--button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: -15px;
}

.forgot-password--button a {
    color: var(--green-600);
}

.forgot-password--button a:hover,
#sign-in-up-switch a:hover {
    color: var(--green-800);
    cursor: pointer;
}

.forgot-password-p {
    font-weight: 400;
    font-size: 1.1rem;
    color: rgb(48, 48, 48);
}

.profile-img--signup {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.1);
}

.profile-img-signup--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.sign-in-popup--buttons {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 25px;
}

.sign-in-popup--buttons a,
.sign-in-popup--buttons button {
    width: 80px;
    border-radius: 5px;
    text-decoration: none;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sign-in--button {
    background-color: var(--green-600);
    color: white;
}

.sign-in--button:hover {
    background-color: var(--green-800);
}

.back--button {
    border: 1.5px var(--green-600) solid;
    color: var(--green-600);
    background-color: transparent;
}

.back--button:hover {
    background-color: var(--grey-300-transparent-1);
}


@media (max-width: 768px) {
    .back-button {
        font-size: 2rem;
        padding-right: 5px;
    }

    .contact-section {
        padding: 25px 35px;
    }

    .sign-in-up--background {
        width: 80%;
        min-width: 520px;
    }

    .contact-header .back-button {
        top: 40px;
        left: 40px;
    }

    .contact-header {
        margin-bottom: 30px;
    }

    #name-sign-up--container {
        flex-direction: column;
    }

    .sign-up--text {
        width: 100%;
    }

    #first-name--sign-up {
        margin-bottom: 20px;
    }

    .contact-header h2 {
        margin: auto;
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media (max-width: 580px) {
    .sign-in-up--background {
        background-color: transparent; 
        box-shadow: none;
        min-width: auto;
    }
    
    .contact-section {
        padding: 0;
        animation-delay: 0s;
    }

    #sign-up--container .back-button {
        top: 20px;
        left: 20px;
    }

    .contact-header {
        margin-bottom: 50px;
    }

    .form-container {
        row-gap: 20px;
    }

    .submit--sign-in {
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .sign-in-up--background {
        width: 90%;
    }
}

@media (max-width: 415px) {
    #reset-password--title {
        margin-left: 35px;
    }
}

@media (max-width: 385px) {
    .sign-in-up--background {
        width: 95%;
    }
}

@media (max-width: 580px) {
    .forgot-password-p {
        margin-bottom: 20px;
    }
    
    .email-confirmation--container {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
    }
    
    .email-confirmation--container h1 {
        margin-bottom: 20px;
    }

    .resend-email--container {
        margin-top: 20px;
    }

    .email-confirmation--background {
        background-color: transparent;
        padding: 30px;
    }
}