body {
  margin: 0;
  padding: 0;
  background-color: rgba(245, 245, 245, 1);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
}

.course-info {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.tutor-info {
  text-align: center;
  background-color: white;
  margin-right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.tutor-info img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.rating {
  margin-top: 5px;
  color: #888;
  color: #ffc107;
  font-weight: bolder;
}

.num-of-ratings--p {
  color: var(--grey-300-transparent-7);
}

.star-rating {
  font-weight: bolder;
  margin-left: 5px;
}

.book-session-btn {
  padding: 10px 20px;
  background-color: rgb(0, 172, 151);
  color: #fff;
  border: none;
  border-radius: 20px;
  /* Changed border-radius for more defined shape */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 5px;
}

.book-session-btn:hover {
  background-color: var(--green-800);
}

.tutor-showcase {
  text-align: left;
  /* Changed to start from top left */
  padding: 20px;
  /* Added padding for better spacing */
}

.tutor-showcase h3 {
  font-size: 24px;
  color: #464646;
  margin-bottom: 10px;
  font-weight: normal;
  margin-top: 0px;
}

.tutor-showcase p {
  color: #666;
  margin-bottom: 5px;
  /*So they break into a new line when it exceeds 500px*/
  max-width: 500px;
}

.fas.fa-graduation-cap {
  color: black;
  /* Set the color of the icon to black */
}

.tutor-showcase .show-more {
  color: red;
  cursor: pointer;
}

.description-full {
  display: none;
}

.description-full.show {
  display: block;
}

.tutor-box--profile {
  display: flex;
}

.tutor-box--profile .material-symbols-outlined {
    font-size: 11rem;
    color: var(--green-600);
    margin-bottom: 10px;
    font-weight: 200;
}

#tutors-selects--container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
}

#select-tutor {
  width: 100%;
  border: none;
  padding-right: 12px;
  padding-left: 12px;
}

.tutors-select--container {
  padding-right: 10px;
}

#tutors-search--container {
  width: 75%;
  min-width: 850px;
  height: 250px;
  max-width: 1200px;
}

.tutor-box--container {
  width: 85%;
  height: 400px;
  justify-content: center;
  margin-bottom: 30px;
  max-width: 900px;
}

.tutor-box--container-mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tutor-box--field p {
  margin: 0;
}

.tutor-box--course {
  max-width: 35%;
  min-width: 35%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.1);
  height: 100%;
  justify-content: center;
}

.tutor-icon {
  color: var(--green-600);
  margin-right: 10px;
}

.tutor-icon,
.course-title > p {
  font-size: 1.4rem;
}

.course-title > p {
  margin: 0;
  text-align: center;
}

.course-title {
  width: 80%;
  align-items: flex-start;
  justify-content: center;
}

.tutor-rating--container {
  color: var(--yellow);
  align-items: flex-start;
}

.tutor-rating--container > div > span {
  margin-left: -2px;
}

.tutor-rating-info--container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tutor-rating--container > p {
  font-size: 1.1rem;
  margin: 0;
}

.stars--container {
  display: flex;
  margin-left: 10px;
}

#book-session--button {
  width: 130px;
  border-radius: 10px;
  padding: 10px 0;
  margin: 0;
  margin-top: 10px;
}

.tutor-box--tutor {
  align-items: flex-start;
  height: 100%;
  padding: 50px;
  box-sizing: border-box;
  justify-content: center;
  flex-grow: 1;
  row-gap: 20px;
}

.tutor-box--field {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.filled-star {
font-variation-settings:
'FILL' 1,
'wght' 400,
'GRAD' 0,
'opsz' 24
}

.larger-size {
  font-size: 1.6rem;
}

.tutor-box--tutor > h3 {
  text-align: start;
}

.very-small-title {
  font-weight: 300;
  letter-spacing: 2px;
  margin: 0;
  color: black;
  font-size: 1rem;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

#hourly-rate--container > div > .bold,
#hourly-rate--container > div > .regular { 
  margin: 5px 0;
}

.large-bold-title {
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  color: var(--bs-gray-800);
}

#tutors-available--container > h1 {
  margin: 60px 0 17px 0;

}

.small-hr {
  width: 18%;
  height: 2px;
  align-self: flex-start;
  margin: 0;
  background-color: rgba(10, 10, 10, 0.5);
  border: none;
}

#tutors-hr {
  width: 10%;
  align-self: center;
  margin-bottom: 90px;
}

.course-title {
  margin: 10px 0 20px 0;
}

#tutors-available--container {
  width: 100%;
  margin-top: 200px;
}


/* MOBILE FILTERS */
#filter-icon {
  color: var(--green-600);
  font-size: 1.3rem;
  border: 1.5px var(--green-600) solid;
  border-radius:  50%;
  padding: 5px;
  margin-left: 10px;
}

#filter-icon:hover {
  background-color: rgba(0,172,151,0.07);
  cursor: pointer;
}

#mobile-filter--container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: whitesmoke;
  z-index: 201;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.course-filter-radio {
  display: none;
}

#courses-options--container,
#languages-options--container {
  height: 190px;
  width: 50%;
  overflow-y: scroll;
  align-self: center;
  border: 1.2px var(--green-600) solid;
  border-radius: 7px;
  position: relative;
}

.courses-radio--container label {
  background-color: transparent;
  color: black;
  padding: 7px;
  width: 100%;
}

.course-filter-radio:checked + label {
  background-color: var(--green-600);
  color: white;
}

.no-borders {
  border: none;
}

#mobile-filter--content {
  padding: 90px 0 110px 0;
  width: 90%;
}

.choice-chip {
  /* width: 90px; */
  height: 50px;
  border-radius: 30px;
  border: 1.5px var(--green-600) solid;
  color: var(--green-600);
  background-color: transparent;
}

.selected-chip {
  background-color: var(--green-600);
  color: white;
}

.mobile-filter--title {
  margin-top: 25px;
  margin-bottom: 10px;
  z-index: 100;
}

#ratings-options--container,
#rates-options--container {
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 15px;
  column-gap: 10px;
}

.sticky {
  position: sticky;
  top: 0;
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes fade-out {
  0% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}

.fade-in {
  animation: fade-in 1s ease-in-out;
}

.fade-out--filters {
  animation: fade-out 0.5s ease-in-out;
}

.profile-picture-img--tutors {
  margin-bottom: 35px;
  margin-top: 20px;
  box-shadow: none;
}

.rating-container--mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--yellow);
  column-gap: 4px;
}

.info-container-tutor-box--mobile p {
  margin:  0;
  font-size: 0.8rem;
  font-weight: 500;
}

.info-container-tutor-box--mobile .material-symbols-outlined {
  font-size: 1.2rem;
}

.info-container-tutor-box--mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  margin-left: -5px;
}

.info-container-tutor-box--mobile p {
  margin: 0;
}

.info-container-tutor-box--mobile > p {
  color: var(--yellow);
}


@media (max-width: 1024px) {
  #tutors-search--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 180px;
  }

  #tutors-search--container #search-bar {
    margin: 0;
  }
}

@media (max-width: 950px) {
  #tutors-available--container {
    margin-top: 150px;
  }

  .tutor-box--course {
    max-width: 43%;
    min-width: 43%;
  }

  #tutors-search--container {
    width: 80%;
    min-width: auto;
  }
}

@media (max-width: 768px) {
  .choice-chip {
    height: 40px;
    border-radius: 20px;
  }

  #courses-options--container,
  #languages-options--container {
    width: 60%;
  }

  #ratings-options--container,
  #rates-options--container {
    width: 80%;  
  }

  .tutor-box--container {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    padding: 25px 10px 25px 0;
    height: fit-content;
    align-items: center;
    max-width: 467px;
  }

  .tutor-box--course {
    box-shadow: none;
    background-color: transparent;
  }

  .tutor-box--profile span {
    font-size: 8rem;
  }

  .tutor-box--tutor {
    padding: 0;
    margin-left: 10px;
    padding-right: 10px;
  }

  .tutor-rating--container {
    margin: 0;
  }

  .tutor-box--tutor h3 {
    margin: 0;
  }

  #language--container {
    margin-bottom: 0;
  }

  #tutors-hr {
    margin-bottom: 50px;
    width: 45%;
  }
}

@media (max-width: 550px) {
  #tutors-search--container {
    padding: 30px;
  }
}

@media (max-width: 540px) {
  .font-not-loaded .tutor-box--profile .material-symbols-outlined {
    width: 44.5px;
    height: 44.5px;
    margin: 6px;
  }

  .font-not-loaded .info-container-tutor-box--mobile .material-symbols-outlined {
    display: none;
  }

  .font-not-loaded .info-container-tutor-box--mobile {
    margin-left: 2px;
  }
  
  .tutor-box--container {
    flex-direction: column;
    max-width: none;
    padding: 5px;
    padding-left: 0;
    margin-bottom: 15px;
    box-shadow: 0 0.3px 3px rgba(0, 0, 0, 0.05);
  }

  #tutors-available--container {
    margin-top: 75px;
    width: 95%;
  }

  #tutors-search--container {
    padding: 20px;
  }

  #tutors-available--container {
    min-width: 285px;
    width: 94%;
  }

  #language-container--tutor-box,
  #rate-container--tutor-box {
    display: none;
  }

  .tutor-box--profile .material-symbols-outlined {
    font-size: 3.5rem;
    margin: 0;
  }

  .tutor-box--course {
    width: fit-content;
    max-width: auto;
    min-width: auto;
  }

  .tutor-box--tutor {
    padding: 0;
    row-gap: 6px;
  }

  .tutor-box--tutor h3 {
    font-size: 1.1rem;
    font-weight: 400;
  }

  .profile-picture-img--tutors {
    margin: 6px;
  }
}

@media (max-width: 480px) {
  #courses-options--container,
  #languages-options--container {
    width: 80%;
    height: 150px;
  }

  #ratings-options--container,
  #rates-options--container {
    width: 100%;  
  }
}


@media (max-width: 380px) {
  #ratings-options--container,
  #rates-options--container {
    row-gap: 10px;
    column-gap: 5px;
  }
}