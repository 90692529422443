/* ABOUT */
#about--container {
    width: 80%;
    margin-top: 85vh;
    line-height: 1.5rem;
    max-width: 1500px;
}

#vision-mission--container {
    display: grid;
    align-items: start;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    column-gap: 80px;
    row-gap: 10px;
    margin: 70px 0 80px 0;
    text-align: justify;
    color: var(--grey);
    width: 80%;
}

#about-image {
    background-image: linear-gradient(rgba(10, 10, 10, 0.1), rgba(0, 0, 0, 0.4)), url("https://studytron.blob.core.windows.net/studytronstatic/about/about-subimage-1920.webp");
    background-size: cover;
    border-radius: 20px;
    background-position: center;
    width: 90%;
    height: 550px;
    margin-bottom: 110px;
    box-shadow: 0 70px 100px rgba(0,0,0,0.2);
}

#why-us--container {
    display: grid;
    grid-template-columns: 0.9fr 1fr;
    column-gap: 50px;
    width: 90%;
    row-gap: 70px;
}

.h3-title {
    font-weight: 800;
    font-size: 2rem;
    width: 70%;
    line-height: 2.6rem
}

.down-arrow {
    transform: rotate(-180deg);
    margin-bottom: 8px;
    margin-right: 10px;
    color: var(--green-600);
    font-weight: 800;
}

.why-us-subtitle {
    font-size: 1.4rem;
    font-weight: 300;
    margin: 0;
    margin-bottom: 6px;
}

.why-us--text {
    align-items: flex-start;
    margin-bottom: 20px;
    text-align: justify;
}


/* SMALL INTRO */
.about-icon {
    color: var(--green-600);
    font-size: 4rem;
    align-self: center;
    padding: 20px;
}

.small-title {
    color: var(--green-600);
    margin: 0;
}

#smallintro--container {
    width: 85%;
    margin-bottom: 40px;
}

#smallintro--container > h1,
.small-intro--title {
    font-size: 2.7rem;
    font-weight: 800;
    margin: 10px 0;
    text-align: center;
}

.small-intro--title {
    font-family: var(--title-font-family);
    letter-spacing: 0.1rem;
}

#smallintro--container > hr {
    height: 1.5px;
    width: 150px;
    border: none;
    background-color: var(--grey);
    margin: 0;
    margin-bottom: 65px;
}

#smallintro--container > p {
    text-align: justify;
}

.medium-size {
    font-size: 1.3rem;
}

.green-bar {
    width: 4px;
    height: 100%;
    background-color: var(--green-600);
    border-radius: 5px;
    position: absolute;
    z-index: 100;
    transition: width 0.5s ease-in-out; 
}

@keyframes widthAnimation {
    from {
        width: 100%;
    }
    to {
        width: 4px;
    }
}

.expand {
    animation: widthAnimation 1s forwards;
}

.description--container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    height: fit-content;
    margin-bottom: 50px;
    position: relative;
}

.green-p-about {
    color: var(--green-600);
    font-weight: 500;
}

.descriptions--container {
    display: flex;
    flex-direction: column;
}

.description--about {
    padding: 5px 15px;
    align-self: center;
    font-size: 1rem;
    letter-spacing: 0.03rem;
}


@media (max-width: 1024px) {
    #about--container {
        width: 90%;
    }    
}

@media (max-width: 800px) {
    #smallintro--container {
        width: 90%;
    }

    #about-image {
        height: 460px;
    }
}

@media (max-width: 768px) {
    #about--container {
        margin-top: 75vh;
    }
}

@media (max-width: 730px) {
    #about-image {
        background-image: linear-gradient(rgba(10, 10, 10, 0.1), rgba(0, 0, 0, 0.4)), url("https://studytron.blob.core.windows.net/studytronstatic/about/about-subimage-730.webp");
    }    
}

@media (max-width: 705px) {
    .description--container {
        flex-direction: column;
        align-items: flex-start;
        justify-items: flex-start;
    }

    .description--about {
        order: 1;
    }

    .description--container:nth-child(odd) .about-icon {
        align-self: flex-start;
    }
    
    .description--container:nth-child(even) .about-icon {
        align-self: flex-end;
    }

    .description--container:nth-child(even) .green-bar {
        order: 2;
        right: 10px !important;
    }

    .description--container:nth-child(odd) .green-bar {
        right: 0 !important;
        left: 10px !important;
    }

    #why-us--container {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    .green-bar {
        height: 3px;
        min-width: 55%;
        top: 100px;
    }

    .h3-title {
        width: 100%;
    }

    #smallintro--container > hr {
        width: 90px;
    }
}

@media (max-width: 550px) {
    #about--container {
        margin-top: 520px;
    }
}

@media (max-width: 540px) {
    #about-image {
        background-image: linear-gradient(rgba(10, 10, 10, 0.1), rgba(0, 0, 0, 0.4)), url("https://studytron.blob.core.windows.net/studytronstatic/about/about-subimage-540.webp");
        width: 100%;
    }   
}

@media (max-width: 380px) {
    #about--container {
        width: 80vw;
    }

    #smallintro--container {
        width: 100%;
    }
    
    #smallintro--container h1 {
        font-size: 2.2rem;
        text-align: center;
    }

    #vision-mission--container {
        width: 100%;
        grid-template-columns: 1fr;
    }

    #about-image {
        background-position: center;
    }

    #why-us--container {
        width: 100%;
        grid-template-columns: 1fr;
        row-gap: 30px;
    }
}