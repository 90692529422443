#booking-page {
    padding: 30px;
    padding-top: 0;
    width: 100%;
    height: 100vh;
    padding-bottom: 100px;
}

#booking--title {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    background-color: white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

#booking--title > a {
   align-self: flex-start;
}

#booking--container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    font-size: 1.05rem;
}

#booking--container .all-bookings--current-day span {
    font-size: 2.4rem;
}

#booking--container .all-bookings--current-day h5 {
    font-size: 1.6rem;
    font-weight: 400;
}

#booking--calendar {
    width: 50%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 50px;
    bottom: 0;
    top: 79px;
}

#booking--calendar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 172, 151, 0.4);
    background-color: #f5f5f5;
    border-radius: 10px;
}

#booking--calendar::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
}

#booking--calendar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--green-600);
}

#booking--container {
    margin-top: 90px;
}

/* .vertical-separator {
    background-color: var(--green-600);
    width: 2px;
    height: 100vh;
    margin-right: 70px;
    position: fixed;
    top: 0;
    z-index: -10;
    opacity: 0.4;
} */

#booking--time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-left: 55%;
    height: 100%;
    padding-top: 30px;
}

.choice-chip-hour {
    width: 85px;
    height: 35px !important;
    font-size: 1rem;
    border-width: 1.5px !important;
}

#available-hours--container--settings {
    display: flex;
    flex-direction: column;
}

#available-hours--container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    margin-top: 15px;
    max-width: 475px;
    row-gap: 10px;
}

#booking-button {
    width: 120px;
    height: 45px;
}

#red-button {
    background-color: var(--red-600);
}

#red-button:hover {
    background-color: var(--red-800);
}

.not-found {
    font-size: 1.5rem;
    color: grey;
}


/* ALL BOOKINGS */
#all-bookings--calendar {
    justify-content: center;
}

.booking-info-box--container {
    background-color: white;
    border-radius: 4px;
    width: 100%;
    padding: 10px 25px 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.booking-info-box--container-height-pending {
    max-width: 1100px;
    align-self: flex-start;
}

#all-bookings--content {
    width: 65%;
    margin-left: 35%;
}

#all-bookings--calendar {
    width: 35%;
}

#all-bookings--content {
    padding: 0 40px 110px 40px !important;
}

.all-bookings--current-day {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 12px;
    /* width: 100%; */
}

.booking--hours {
    width: 100%;
    padding-bottom: 100px;
}

.available--titles {
    color: rgba(97, 97, 97, 0.8);
    font-size: 1.5rem;
    font-weight: 300;
}

.booking--course {
    margin-top: 30px;
    width: 100%;
}

.booking--course #courses-options--container {
    width: 65%;
    margin-top: 15px;
}

.booking--course #courses-options--container::-webkit-scrollbar {
    display: none;
}

.scrollable {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}

.no-bookings-message {
    color: grey;
    font-size: 1.5rem;
    font-weight: 400;
    position: absolute;
    top: 50%;
}

.all-bookings--current-day h5 {
    margin: 0;
    margin-left: 3px;
}

.all-bookings--current-day span {
    font-size: 2rem;
}

.booking-info-box--title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    column-gap: 5px;
}

.pending-buttons {
    display: flex;
    flex-direction: row;
}

.booking-info-box--title h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
}

.booking-info-box--info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.booking-info-box--section {
    margin-bottom: 5px;
}

.booking-info-box--section-title {
    margin-right: 6px;
    font-weight: 600;
    color: var(--green-600);
}

.booking-info-box--section p {
    margin: 0;
}

.booking-info-box--contact-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: italic;
}

.booking-info-box--contact-section a {
    margin: 0;
    margin-left: 5px;
    font-size: 1rem;
    text-decoration: underline;
    color: rgb(49, 49, 49);
}

.booking-info-box--contact-section span {
    font-style: italic;
    font-size: 1.1rem;
    color: var(--green-600);
}

.right-border {
    border-right: 2px var(--green-600) solid;
    padding-right: 20px;
}

.left-padding {
    padding-left: 20px;
}

.booking-info-box--contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}

#all-bookings--container {
    width: 100vw;
    height: 100vh;
}

#all-bookings--content {
    margin-top: 88px;
}

#all-bookings--calendar {
    top: 77px;
}

.all-bookings--day {
    font-weight: 300;
    align-self: flex-start;
    color: var(--green-600);
    margin: 0;
    margin-top: 30px;
}

#booking--time p {
    color: grey;
    font-size: 1.2rem;
}

.all-bookings-tutor--button {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 125px;
    padding: 5px;
    border: 1.5px var(--green-600) solid;
    border-bottom: none;
    background-color: white;
    color: var(--green-600);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.all-bookings-tutor--button--selected {
    background-color: var(--green-600) !important;
    color: white;
    cursor: default !important;
}

.all-bookings-tutor--button:hover {
    cursor: pointer;
    background-color: var(--white-800-transparent-3);
    transition: background-color 0s ease !important;
}

.all-bookings-tutor--buttons {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}

.all-bookings--hour {
    align-self: flex-start;
    font-weight: 200;
    margin-top: 15px;
    font-size: 1.4rem;
    margin-bottom: -5px;
    color: rgba(97, 97, 97, 0.8);
}

.pending-ban--container {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    align-items: center;
    position: relative;
}

.show-more-booking--container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 27px;
    right: 0;
    background-color: white;
    width: 150px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.show-more-booking--container button,
.show-more-booking--container a {
    color: black;
    text-align: center;
    border: none;
    background-color: white;
    height: 29px;
    border-bottom: 1px rgba(0,0,0,0.1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-more-booking--container button:hover,
.show-more-booking--container a:hover {
    background-color: var(--green-600-transparent-500);
}

.more-horiz-icon:hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.7);
}

.show-more-booking--container button:last-child,
.show-more-booking--container a:last-child {
    border-bottom: none;
}

.block-button {
    background-color: var(--red-600);
    border-radius: 5px;
    padding: 3px 20px;
    color: white;
    border: none;
    margin-top: -15px;
}

.block-button:hover {
    background-color: var(--red-800);
    cursor: pointer;
}

.profile--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    margin-left: 30px;
}

.profile-img--bookings {
    width: 150px;
    margin-bottom: 27px;
    height: 150px;
}

.date-time-booking-box {
    display: flex;
    flex-direction: column;
    width: fit-content;
    row-gap: 6px;
    margin-left: 25px;
}

.date-time-booking--subcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
}

.date-time-booking--subcontainer span {
    color: var(--green-600);
}

.date-time-booking--subcontainer p {
    margin: 0;
    white-space: nowrap;
}

.booking-info-box--mobile {
    display: none;
}

.booking-boxes--container {
    width: 100%;
    margin-bottom: 30px;
}


@media (max-width: 1280px) {
    #all-bookings--content {
        width: 65%;
        margin-left: 35%;
    }
    
    #all-bookings--calendar {
        width: 35%;
    }
}

@media (max-width: 1200px) {
    .small-profile {
        font-size: 10rem !important;
    }

    .booking-info-box--container {
        padding: 20px;
    }
}

@media (max-width: 1070px) {
    .booking-info-box--container {
        height: fit-content;
    }

    #all-bookings--content {
        width: 60%;
        margin-left: 40%;
    }
    
    #all-bookings--calendar {
        width: 40%;
    }

    .booking-info-box--contact {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .right-border {
        border-right: none;
    }

    .left-padding {
        padding: 0;
        margin-top: 5px;
    }

    .block-button {
        margin-top: -5px;
    }
}

@media (max-width: 1024px) {
    .booking--course #courses-options--container {
        width: 90%;
    }

    .booking-info-box--container {
        flex-direction: column;
        align-items: flex-start;
    }

    .date-time-booking-box {
        margin: 0;
        margin-top: 5px;
        flex-direction: row;
        column-gap: 25px;
    }
}

@media (max-width: 900px) {
    #all-bookings--calendar {
        margin-top: 150px !important;
    }

    #all-bookings--content {
        margin-top: 30px !important;
    }

    .booking-info-box--contact {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .right-border {
        border-right: 2px var(--green-600) solid;
    }
    
    .left-padding {
        padding-left: 20px;
        margin: 0;
    }

    #booking--calendar {
        position: static;
        width: 100%;
        overflow: visible;
        padding: 20px;
    }

    .vertical-separator {
        opacity: 0;
    }

    #booking--container {
        flex-direction: column;
        row-gap: 10px;
    }

    #booking--time {
        margin-left: 0;
        width: 90%;
    }

    #available-hours--container {
        grid-template-columns: repeat(5, 1fr);
    }

    .no-bookings-message {
        position: static;
    }

    #all-bookings--content {
        width: 100%;
        margin-left: 0;
    }

    #all-bookings--calendar {
        width: 100%;
    }

    .booking-info-box--container {
        flex-direction: row;
        align-items: center;
    }

    .date-time-booking-box {
        margin: 0;
        flex-direction: column;
    }
}

@media (max-width: 800px) {
    .booking-info-box--contact {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .right-border {
        border-right: none;
    }

    .left-padding {
        padding: 0;
        margin-top: 5px;
    }

    #all-bookings--content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media (max-width: 768px) {
    .h1-title {
        font-size: 1.7rem;
        margin: 0;
    }
}

@media (max-width: 600px) {
    .no-bookings-message {
        font-size: 1.4rem;
        text-align: center;
        padding: 5px;
    }

    #available-hours--container {
        grid-template-columns: repeat(4, 1fr);
    }

    .booking--course #courses-options--container {
        width: 100%;
    }

    .booking-info-box--title h3 {
        font-size: 1.3rem;
    }

    .pending-ban--container span {
        font-size: 1.3rem;
    }

    .pending-ban--container {
        column-gap: 20px;
    }
}

@media (max-width: 550px) {
    #booking--calendar {
        padding: 0;
    }

    .booking-info-box--container {
        background-color: transparent;
        margin-top: 0;
        padding: 7px 9px !important;
        border-radius: 0;
        border-bottom: 0.5px var(--grey-300-transparent-5) solid;
    }

    .booking-info-box--container:hover {
        background-color: var(--green-600-transparent-200);
    }

    .all-bookings--day {
        margin-bottom: 7px;
        font-size: 1.4rem;
        margin-top: 0;
    }

    .booking-info-box--title h3 {
        font-size: 0.9rem;
    }

    .booking-info-box--section {
        font-size: 0.7rem;
        color: var(--grey-300);
    }

    .date-time-booking-box {
        display: none;
    }

    .booking-info-box--section-title {
        display: none;
    }

    .booking-info-box--mobile {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        margin-left: 4px;
    }

    .booking-info-box--mobile p {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .booking-info-box--section {
        display: flex;
        flex-direction: row;
        margin: 0;
    }

    .booking-info-box--title {
        margin-bottom: 0;
    }

    .pending-ban--container {
        width: 100%;
        justify-content: space-between;
    }

    .more-horiz-icon {
        position: absolute;
        top: 8.8px;
        right: 0;
    }

    .h1-title {
        font-size: 1.4rem;
    }

    .back-arrow {
        font-size: 1.35rem;
    }

    #title-back {
        padding: 8px;
    }

    #all-bookings--calendar {
        margin-top: 100px !important;
    }

    .all-bookings--hour {
        font-size: 1rem;
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

@media (max-width: 430px) {
    #available-hours--container {
        grid-template-columns: repeat(3, 1fr);
    }

    #booking--container .all-bookings--current-day h5 {
        font-size: 1.2rem;
    } 

    #booking--container .all-bookings--current-day span {
        font-size: 2rem;
    }

    .available--titles {
        font-size: 1.3rem;
    }

    #booking-button {
        width: 110px;
        height: 40px;
        font-size: 1rem;
    }

    .choice-chip-hour {
        font-size: 0.9rem;
    }

    .booking--course #courses-options--container {
        font-size: 0.9rem;
    }
}

@media (max-width: 390px) {
    .booking-info-box--container {
        padding: 10px;
        padding-top: 0;
    }

    #all-bookings--box {
        width: 93%;
    }

    .react-calendar {
        width: 290px !important;
    }
}

@media (max-width: 370px) {
    #available-hours--container {
        grid-template-columns: repeat(2, 90px)
    }
}