#contact--container {
  margin-top: 85vh;
  width: 55%;
  justify-content: center;
}

#contact--container .submit-button {
  margin-top: 20px;
}

.mail {
  font-weight: 800;
  color: var(--icons-green);
}

.contact-header h2 {
  color: #000;
  font-size: 50px;
  font-weight: bold;
  margin: 0;
}

.contact-info p {
  color: rgba(10, 10, 10, 0.5);
  font-size: 18px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 5em;
  font-weight: lighter;
}

.form-row {
  margin-top: 1.25em;
}

.label {
  color: rgba(10, 10, 10, 0.5);
  font-size: 15px;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.input-group--contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-group--contact input {
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  width: 49.5%;
}

.input-group input:last-child {
  margin-right: 0;
}

input[type="email"],
textarea {
  height: 3.125em;
  border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  -moz-border-radius: 0.25em;
  -ms-border-radius: 0.25em;
  -o-border-radius: 0.25em;
}

textarea,
.subject-container {
  padding: 0.625em;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 0.25em;
}

textarea {
  resize: vertical;
  height: 13em;
}

.submit-button {
  width: 15%;
  height: 55px;
  background: rgb(0, 172, 151);
  color: #fff;
  border: none;
  border-radius: 100px;
  font-size: 15px;
  cursor: pointer;
}

.submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.red{
  color: red;
}


@media (max-width: 950px) {
  #contact--container {
    width: 70%;
  }
}

@media (max-width: 768px) {
  #contact--container {
    margin-top: 74vh;
  }  
}

@media (max-width: 650px) {
  #contact--container {
    width: 85%;
  }
}

@media (max-width: 550px) {
  #contact--container {
    margin-top: 520px;
  } 
}

@media (max-width: 380px) {
  #contact--container .form-container {
    margin-top: 0;
  }

  .input-group--contact {
    flex-direction: column;
  }

  .input-group--contact input {
    width: 100%;
  }

  .input-group--contact #firstName {
    margin-bottom: 5px;
  }
}