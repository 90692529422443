#general-settings--container {
    backdrop-filter: blur(5px);
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    top: 0%;
    left: 0%;
    overflow-y: scroll;
    animation: fade-in 1s ease-in;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#general-settings {
    background-color: white;
    width: 45%;
    position: absolute;
    top: 0%;
    right: 0%;
    padding: 30px 43px 95px 30px;
    height: -webkit-fill-available;
    min-height: max-content;
}

.available-hours--settings {
    margin-top: 20px;
}

#settings-title h1 {
    margin: auto;
    font-size: 2.5rem;
    font-weight: 500;
}

#general-settings form .sign-up--text {
    margin-top: 1.25em;
}

.settings-subtitle {
    font-size: 1.7rem;
    color: var(--green-600);
    font-weight: 400;
    margin: 0;
    /* margin-bottom: -5px;
    margin-top: 30px; */
}

#advanced-settings--content div hr {
    margin-bottom: 50px;
}

#buttons-settings--container {
    margin-top: 30px;
}

#buttons-settings--container button,
#grey-button {
    width: 100%;
    margin: 15px 0 0 0;
    border-radius: 7px;
}

#grey-button {
    background-color: grey;
    width: 100%;
}

#save-signout--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#save-signout--container > button {
    width: 49%;
}

#grey-button:hover {
    cursor: pointer;
    background-color:var(--grey-500);
}

#advanced-settings--title {
    width: 100%;
}

#advanced-settings--title a {
    align-self: flex-start;
    color: var(--green-600);
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#general-settings form .settings-subtitle {
    margin-top: 30px;
}

#advanced-settings--title a span {
    font-size: 2.2rem;
}

#advanced-settings--title > h1 {
    margin: auto;
}

#advanced-settings--container {
    width: 100%;
    padding: 30px;
    padding-bottom: 100px;
}

#advanced-settings--content {
    width: 70%;
    margin-top: 50px;
}


@keyframes slide-in {
    0% {
      transform: translateX(47%);
    }
    100% {
      transform: translateX(0);
    }
}

.slide-out {
    animation: slide-out 1s ease-in-out !important;
}

.fade-out {
    animation: fade-out 1s ease-in !important;
}

@keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(47%);
    }
}

#general-settings {
    animation: slide-in 1s ease-in-out;
}

.account-activation--settings {
    font-size: 1rem;
    color: grey;
    margin-top: 10px;
}

.account-activation-link--settings {
    color: var(--green-600);
    cursor: pointer;
}

.account-activation-link--settings:hover {
    color: var(--green-800);
}


/* CALENDAR */
#hours--container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    row-gap: 10px;
}

#calendar-hour--container {
    justify-content: space-between;
    margin-top: 20px;
}

#date-icon,
#hour-icon {
    color: var(--green-600);
    margin-right: 4px;
}

#date-result,
#hour-result {
    margin: 0;
}

.disabled-button {
    background-color: var(--green-800) !important;
}

.disabled-button:hover {
    background-color: var(--green-800) !important;
    cursor: default !important;
}

.date-time--container {
    margin-bottom: 25px;
    align-items: flex-start;
    font-weight: 600;
}

#date-time {
    width: 30%;
    margin: 0 20px 0 20px;
}

#available-hours--container .label {
    margin-top: 1.25em;
}

.save-button {
    border: none;
    border-radius: 5px;
    background-color: var(--green-600);
    color: white;
    padding: 5px 30px 5px 30px;
    /* margin: 0 0 -5px 40px; */
    align-self: flex-end;
}

.save-button:hover {
    background-color: var(--green-800);
    cursor: pointer;
}

.settings-title-save {
    justify-content: space-between;
}

#general-settings form #name-sign-up--container {
    margin-top: 0;
}

#settings-title .large-bold-title {
    font-size: 2.2rem;
}

#calendar-hours--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin-right: 30px;
}

.banned-user-box--container {
    flex-direction: row;
    padding: 0;
    padding-right: 15px;
    height: 120px;
}

.banned-users--container {
    flex-direction: column;
    column-gap: 0;
    row-gap: 20px;
    overflow: auto;
}


/* BANNED USERS */
.banned-users--container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    padding-right: 10px;
    padding-bottom: 50px;
}

.banned-users--container::-webkit-scrollbar {
    width: 4px;
}
  
.banned-users--container::-webkit-scrollbar-track {
    background-color: #CDF1EC;
    border-radius: 4px;
}
  
.banned-users--container::-webkit-scrollbar-thumb {
    background: var(--green-600);
    border-radius: 4px;
}

.banned-user-box--container {
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 70px;
    max-height: 70px;
}

.banned-user-box--info {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.profile-ban,
.profile-ban--more-info {
    color: var(--green-600);
    font-weight: 200;
    width: fit-content;
}

.profile-ban {
    margin-right: 15px;
    font-size: 4.5rem;
}

.profile-ban--more-info {
    font-size: 10rem;
}

.banned-user-box--h3 {
    font-size: 1.2rem;
    margin: 0;
    line-height: normal;
}

.unban-button,
.more-info-button {
    border-radius: 5px;
    padding: 5px 10px;
    border: none;
    color: white;
    cursor: pointer;
}

.more-info-button {
    background-color: var(--green-600);
}

.more-info-button:hover {
    background-color: var(--green-800);
}

.unban-button {
    background-color: var(--red-600);
}

.unban-button:hover {
    background-color: var(--red-800);
}

.banned-user--buttons {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.banned-user-more-info--container {
    background-color: white;
    border-radius: 5px;
    position: fixed;
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-top: 20px;
    box-shadow: 5px 5px 50px rgba(0,0,0,0.1);
    min-width: 300px;
    align-items: center;
}

.banned-user-box--contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banned-user-box--contacts h3 {
    margin-bottom: 20px;
}

.banned-user-more-info--bg {
    backdrop-filter: blur(5px);
    position: fixed;    
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 200;
}

.settings-profile-buttons--container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 15px;
}

.settings-profile-buttons--container button,
.cancel-profile-settings,
.edit-profile-settings {
    border-radius: 5px;
    padding: 4px 0;
    width: 75px;
    border: none;
    color: white;
    cursor: pointer;
}

.edit-profile-settings {
    background-color: var(--green-600);
}

.profile-circular-overlay {
    position: absolute;
    width: 150px;
    height: 150px;
    pointer-events: none;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cropper--container {
    position: relative;
    width: 100%;
    max-width: 200px;
    margin: auto;
    overflow: hidden;
    background-color: white;
}

.profile-picture--no-edit-settings {
    width: 180px;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px #cccccc solid;
    border-radius: 50%;
}

.cancel-profile-settings {
    background-color: var(--red-600);
}

.remove-profile-settings:hover {
    background-color: var(--green-800);
}

.cancel-profile-settings:hover {
    background-color: var(--red-800);
}

.profile-img--banned-user {
    margin-left: 8.5px;
    margin-right: 22px;
    min-width: 55px;
    max-width: 55px;
    min-height: 55px;
    max-height: 55px;
}

.profile-img--banned-user-more {
    width: 120px;
    height: 120px;
    margin: 20px;
}

#close-icon--form {
    margin-right: 2px;
}

#close-icon--form:hover {
    color: var(--black-transparent-7);
    cursor: pointer;
}


@media (max-width: 1250px) {
    #calendar-hour--container {
        flex-direction: column;
    }

    #calendar-hours--container {
        width: 80%;
        margin-bottom: 50px;
    }

    #date-time {
        width: 50%;
    }
}

@media (max-width: 1024px) {
    #general-settings {
        width: 60%;
    }

    #settings-title {
        margin-bottom: 20px;
    }

    #settings-title .large-bold-title {
        font-size: 2rem;
    }

    #general-settings form #name-sign-up--container {
        flex-direction: column;
        width: 100%;
    }

    #general-settings form #name-sign-up--container .form-row {
        width: 100%;
    }

    #general-settings form #name-sign-up--container .form-row input {
        width: 100%;
    }

    #calendar-hours--container {
        justify-content: center;
        column-gap: 40px;
        width: 100%;
    }
}

@media (max-width: 900px) {
    #date-time {
        width: 60%;
    }
}

@media (max-width: 850px) {
    #advanced-settings--content {
        width: 80%;
    }
}

@media (max-width: 780px) {
    #calendar-hours--container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
        margin-right: 0;
        margin-bottom: 0;
    }

    #calendar-hour--container {
        row-gap: 40px;
    }

    #hours--container {
        grid-template-columns: repeat(4, 1fr);
    }

    #date-time {
        border-top: 1.5px var(--green-600) solid;
        padding-top: 20px;
    }
}

@media (max-width: 750px) {
    #general-settings {
        width: 70%;
    }
}

@media (max-width: 650px) {
    #general-settings {
        width: 80%;
    }
}

@media (max-width: 600px) {
    #advanced-settings--content {
        width: 90%;
    }
}

@media (max-width: 550px) {
    #general-settings {
        width: 100%;
    }

    #general-settings--container {
        backdrop-filter: none;
        animation: fade-in 1.5s ease-in;
    }

    @keyframes slide-in {
        0% {
          transform: translateX(110%);
        }
        100% {
          transform: translateX(0);
        }
    }
    
    .slide-out {
        animation: slide-out 1.5s ease-in-out !important;
    }
    
    @keyframes slide-out {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(110%);
        }
    }

    #advanced-settings--content {
        width: 100%;
        margin-top: 25px;
    }

    #advanced-settings--title .h1-title {
        padding: 0 10px 0 10px;
        font-size: 1.8rem;
    }

    #advanced-settings--title a span {
        font-size: 2.1rem;
    }

    #calendar-hour--container {
        flex-direction: column;
    }

    #hours--container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    #date-time {
        width: 80%;
    }

    .profile-ban {
        margin-top: 0;
    }

    .block-button {
        margin-bottom: 10px;
    }
}

@media (max-width: 470px) {
    .banned-user-more-info--container {
        min-width: 260px;
    }
}

@media (max-width: 430px) {
    .banned-user-box--h3 {
        font-size: 1rem;
    }

    .profile-ban {
        margin-right: 5px;
        font-size: 4rem;
    }

    .profile-img--banned-user {
        width: 48px;
        height: 48px;
    }
}

@media (max-width: 400px) {
    #date-time {
        width: 100%;
    }

    .settings-subtitle {
        font-size: 1.5rem;
    }

    .save-button {
        padding: 5px 20px;
    }
}

@media (max-width: 380px) {
    #advanced-settings--container {
        padding: 20px;
    }
}