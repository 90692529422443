.success-payment--button,
.fail-payment--button {
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.success-payment--button {
    background-color: var(--green-600);
}

.success-payment--button:hover {
    background-color: var(--green-800);
}

.fail-payment--button {
    background-color: var(--red-600);
    width: 120px;
}

.payment--buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
}

.notification--container h1 {
    margin-top: 10px;
    margin-bottom: 20px;
}

#try-again--button {
    background-color: transparent;
    border: 1.5px var(--red-600) solid;
    color: var(--red-600);
}

.fail-payment--button:hover {
    background-color: var(--red-800);
}

#try-again--button:hover {
    background-color: rgba(213, 213, 213, 0.3);
}


.subscription-plans--page {
    background: linear-gradient(135deg, rgb(241, 241, 241) 70%, var(--green-600) 30%);
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
}

.subscription-plans--container {
    background-color: white;
    border-radius: 10px;
    padding: 40px 70px;
    padding-bottom: 50px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscription-plans--container h1 {
    font-family: var(--title-font-family);
}

.package--container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    width: 350px;
    height: 350px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
}

.package--container p {
    text-align: center;
    color: rgb(70, 70, 70);
    margin: 0;
}

.package--container h3 {
    color: var(--green-600);
    font-family: 'Domine';
}

.subscription-price--container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.subscription-price--container h2 {
    margin: 0;
    font-weight: 600;
    font-size: 2.5rem;
}

.subscription-price--container p {
    color: grey;
    margin: 0;
    margin-bottom: 3px;
    margin-left: 5px;
}

.package--container button {
    background-color: var(--green-600);
    padding: 6px 15px;
    color: white;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
}

.package--container button:hover {
    background-color: var(--green-800);
}

.packages--container {
    display: flex;
    flex-direction: row;    
    align-items: center;
    column-gap: 30px;
    justify-content: center;
    margin-top: 30px;
}

.no-credit-card-text {
    color: grey;
    font-size: 0.8rem;
    margin: 0;
    margin-top: 4px !important;
}

.get-plan--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
}

.plan-details--container {
    margin-top: 5px;
    text-align: center;
    width: 80%;
    font-size: 1rem;
    color: var(--grey-300);
}

@media (max-width: 1024px) {
    .subscription-plans--container {
        padding: 40px 50px 50px 50px;
    }
}

@media (max-width: 900px) {
    .packages--container {
        flex-direction: column;
        row-gap: 30px;
    }

    .plan-details--container {
        width: 350px;
    }

    .subscription-plans--page {
        height: auto;
        padding: 20px;
    }

    .subscription-plans--container {
        margin-top: 70px;
        margin-bottom: 90px;
        width: 70%;
        padding-top: 35px;
    }
    
    .subscription-plans--container h1 {
        text-align: center;
    }


}

@media (max-width: 640px) {
    .subscription-plans--container {
        background-color: transparent;
        margin-top: 35px;
    }

    .subscription-plans--container .h1-title {
        font-size: 1.5rem;
    }
}

@media (max-width: 510px) {
    .plan-details--container {
        width: 280px;
    }

    .package--container {
        width: 300px;
    }
}

@media (max-width: 375px) {
    .plan-details--container {
        width: 250px;
    }

    .package--container {
        width: 270px;
    }
}