#error--container {
    padding: 30px;
    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
}

.error-title--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.error-title--container h2 {
    margin : 0;
}

.error-description--container {
    margin-top: 30px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
}

.error-icon {
    color: var(--green-600);
    font-size: 2rem;
}

.error-buttons--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
    width: fit-content;
}

.error-buttons--container a {
    width: fit-content;
    height: fit-content;
    padding: 10px 0;
    width: 140px;
    text-decoration: none;
    border-radius: 10px;
    text-align: center;
    font-size: 1rem;
    color: white;
    border: none;
    font-weight: 300;
    margin: 0 !important;
}

.try-again--error {
    border: none;
    background-color: var(--red-600);
    cursor: pointer;
}

.try-again--error:hover {
    background-color: var(--red-800);
}


@media (max-width: 355px) {
    .error-buttons--container {
        flex-direction: column;
        column-gap: 0;
        row-gap: 15px;
        width: 100%;
    }

    .error-buttons--container a {
        width: 100%;
    }
}