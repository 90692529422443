#courses--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 1400px;
}

#courses-available--container {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 170px;
    column-gap: 35px;
    row-gap: 35px;
    width: 100%;
    justify-items: stretch;
}

#courses--container > p,
#tutors-available--container > p {
    font-size: 1.5rem;
    color: grey;
    margin-bottom: 80px;
    text-align: center;
}

.box-courses {
    padding: 30px 20px 20px 20px;
    height: 230px;
    box-sizing: border-box;
    height: 250px;
}

.courses-select--container {
    padding-right: 15px;
    width: 50%;
    min-width: 168px;
}

.courses-select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 1024px) {
    #courses--container {
        width: 85% !important;
    }
}

@media (max-width: 768px) {
    #courses-available--container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (max-width: 878px) {
    #courses-available--container {
        row-gap: 15px;
        column-gap: 15px;
        width: 90vw;
    }
}

@media (max-width: 550px) {
    #courses--container .search--container {
        margin-top: calc(480px - 80px);
    }

    #courses--container {
        width: 90vw !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #courses--container > p {
        font-size: 1rem;
        width: 80%;
    }

    #courses-available--container {
        display: flex;
        flex-direction: column;
        row-gap: 0;
        margin-top: 120px;
    }

    .box-courses {
        display: flex;
        flex-direction: row;
        height: 70px;
        width: 100%;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    #courses-available--container a .element-info-h2,
    #tutor-course-box .course-title p {
        font-size: 1rem !important;
        margin: 0 13px;
        flex-grow: 1;
        font-weight: 300;
    }

    #courses-available--container a .green-p-box,
    #tutor-course-box .green-p-box {
        margin: 0;
        font-size: 0.8rem;
    }
}