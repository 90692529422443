.react-calendar {
    font-family: var(--regular-font-family) !important;
}

.react-calendar__tile {
    color: black;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    color: black;
}

.react-calendar__navigation__next-button:not(:disabled),
.react-calendar__navigation__prev-button:not(:disabled) {
    color: black;
}

.react-calendar__month-view__days .react-calendar__month-view__days__day--weekend {
    color: black;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}

.react-calendar__tile--now:enabled:hover, 
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile:enabled:hover, 
.react-calendar__tile:enabled:focus,
.react-calendar__tile--active {
    background: var(--green-600) !important;
    color: white !important;
    border-radius: 4px;
}

.react-calendar .react-calendar__tile--now {
    background: none;
    color: black;
}

.react-calendar__tile:disabled {
    color: #ababab !important;
}

.react-calendar__tile--booked-green {
    background-color: rgba(0, 172, 151, 0.2) !important;
    color: black !important;
}

.react-calendar__tile--booked-red {
    background-color: rgba(172, 26, 0, 0.2) !important;
    color: black !important;
}

.react-calendar__tile {
    transition: all 0.3s ease;
}