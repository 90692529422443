.privacy-policy--container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}

.privacy-policy--html {
    width: 90%;
    height: 100%;
    border: none;
}

.privacy-policy--button {
    margin-top: 40px;
    margin-bottom: 0;
}