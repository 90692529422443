#navbar--container {
    width: 100%;
    height: 90px;
    z-index: 200;
}

#navbar-titles--container {
    width: 100%;
    padding: 30px;
    justify-content: space-between;
}

#navbar--titles {
    width: 50%;
    max-width: 450px;
    min-width: 380px;
    justify-content: space-between;
}

.navbar--title,
.settings-icon {
    text-decoration: none;
    color: var(--white-transparent-55);
}

.navbar--title:hover,
.settings-icon:hover {
    color: var(--white-transparent-55);
    cursor: pointer;
}

#navbar--titles .navbar--title-active {
    color: white; 
}

.sign-up-navBar {
    color: white;
    border-radius: 100px;
    background-color: var(--green-600);
    text-align: center;
    width: 105px;
    padding: 15px 0 15px 0;
    text-decoration: none;
    justify-content: center;
    margin-right: 20px;
    font-size: 0.9rem;
    font-weight: 300;
    transition: background-color 0.3s ease;
    height: 40px;
    display: flex;
    align-items: center;
}

.sign-up-navBar:hover {
    cursor: pointer;
    background-color: var(--green-800);
}

.settings-icon {
    font-size: 22px;
}

#sign-in--button {
    background-color: transparent;
    border: 1px white solid;
}

#sign-in--button:hover {
    background-color: var(--white-transparent-2);
}


.navbar-icon {
    display: inline-block;
    cursor: pointer;
    align-self: flex-end;
    z-index: 40;
}

.bar1, .bar2, .bar3 {
    width: 27px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 10px;
}
 
.change .bar1 {
    transform: translate(0, 7px) rotate(-45deg);
}
  
.change .bar2 {
    opacity: 0;
}
  
.change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
}

#navbar-background {
    background-color: var(--green-600);
    height: 100vh;
    width: 100%;
    z-index: 39;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    transition: transform 0.7s ease-in-out;
    transform: translateY(-107vh);
}

.slide-in--transform {
    transform: translateY(0) !important; 
}

.fixed {
    position: fixed !important;
}

.navbar-icon--container {
    display: flex;
}

.navbar-icons--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 280px;
    justify-content: space-between;
    margin-right: 25px;
}

.days-left--button {
    color: white;
    border: 2px var(--white-transparent-55) solid;
    padding: 4px 7px;
    border-radius: 10px;
}

.days-left--button:hover {
    background-color: var(--white-transparent-1);
}


/* RESPONSIVENESS */
@media (max-width: 1280px) {
    #navbar--titles {
        width: 45%;
    }
}

@media (max-width: 950px) {
    #navbar--titles {
        width: 50%;
    }
}

@media (max-width: 900px) {
    .navbar-icons--container {
        width: 250px;
    }
}

@media (max-width: 860px) {
    #navbar--titles {
        width: 55%;
    }
}

@media (max-width: 816px) {
    #navbar--container {
        height: fit-content;
        display: flex;
        align-items: center !important;
        justify-content: space-between;
        padding: 20px;
        width: 100vw;
        position: absolute;
    }

    #navbar--titles {
        flex-direction: column !important;
        font-size: 1.7rem;
        width: fit-content;
        height: fit-content;
        padding: 25vw 0 25vw 0;
    }

    #navbar--titles > a {
        margin-bottom: 15px;
    }

    .settings-icon {
        font-size: 1.8rem !important;
        font-weight: 300;
    }
}

@media (max-width: 435px) {
    .days-left--button {
        display: none;
    }
}