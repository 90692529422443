:root {
    --regular-font-family: "Open Sans", sans-serif;
    --title-font-family: "Domine";

    --green-800: rgb(1, 128, 113);
    --green-600: rgb(0, 172, 151);

    --green-600-transparent-500: rgba(0, 172, 151, 0.5);
    --green-600-transparent-200: rgba(0, 172, 151, 0.2);
    
    --red-800: rgb(173, 50, 50);
    --red-600: rgb(217, 77, 77);

    --white-800-transparent-3: rgba(224, 224, 224, 0.3);

    --grey-600: rgba(60, 60, 60);
    --grey-500: rgb(85, 85, 85);
    --grey-400: rgb(112, 112, 112);
    --grey-300: rgb(162, 162, 162);
    --grey-200: rgb(210, 210, 210);
    --grey-100: rgb(239, 239, 239);
    
    --white-transparent-55: rgba(255, 255, 255, 0.55);
    --white-transparent-2: rgba(255, 255, 255, 0.2);
    --white-transparent-1: rgba(255, 255, 255, 0.1);
    
    --black-transparent-7: rgba(0, 0, 0, 0.7);
    
    --grey-300-transparent-7: rgba(162, 162, 162, 0.7);
    --grey-300-transparent-5: rgba(162, 162, 162, 0.5);
    --grey-300-transparent-2: rgba(162, 162, 162, 0.2);
    --grey-300-transparent-1: rgba(162, 162, 162, 0.1);

    --yellow: rgb(239, 172, 87);
}

body {
    box-sizing: border-box;
    margin: 0;
    font-family: var(--regular-font-family);
    font-size: 15px;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    background-color: rgb(241, 241, 241);
    margin: 0;
    padding: 0;
    background-position: center;
    background-size: cover;
}

#root {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: relative;
}

a {
    text-decoration: none;
}

button,
a {
    transition: all 0.4s ease;
}

.flex-row,
#navbar--container,
#navbar--titles,
.small-button,
#how-it-works--container,
#how-it-works--all-info,
.how-it-works--list,
#benefits--sections,
#search-bar,
.why-us--text,
.tutor-box--container,
.course-title,
.tutor-rating--container,
#name-sign-up--container,
#sign-in-up-switch,
#booking--title,
#settings-title,
#advanced-settings--title,
#calendar-hour--container,
.date-time--container,
#sign-in-up--navbar,
#navbar-titles--container,
#course-option--container,
#search-bar-container--form,
.settings-title-save,
#tutor-courses--title,
#title-back {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-column,
#home--container,
.small-box,
#majors--container,
#how-it-works--info,
.benefits--section,
.review-box,
#sign-up-box--container,
#page-intro,
.search--container,
.element-info,
#about--container,
#smallintro--container,
.tutor-box--course,
.tutor-box--tutor,
#tutors-available--container,
#footer--container,
#contact--container,
#contact-intro,
#booking-page,
#advanced-settings--container,
#tutor-course-box,
#tutor-courses--content,
#all-bookings--content,
#tutor-courses-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-overflow {
    overflow: hidden;
    height: 100vh;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.green-p-box {
    border: var(--green-600) 1px solid;
    border-radius: 10px;
    color: var(--green-600);
    text-align: center;
    height: fit-content;
    width: fit-content;
    padding: 7px 10px 7px 10px;
}

.light-grey {
    color: var(--grey-400);
    margin: 0;
}

.light-green {
    color: var(--green-600);
}

.small-box {
    background-color: white;
    box-shadow: 0px 50px 50px -10px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 18px 0px 18px 0px;
    font-size: 1rem;
    height: 210px;
    justify-content: center;
    padding: 0 10px;
}

.small-box:hover {
    cursor: pointer;
    background-color: var(--grey-100);
}

.element-info {
    align-items: center;
}

.light-title {
    font-weight: 300;
    text-align: center;
    color: black; 
}

.underlined-bold {
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}

.element-info > p {
    margin: 0;
}

.small-button {
    color: white;
    border-radius: 100px;
    background-color: var(--green-600);
    text-align: center;
    width: 165px;
    padding: 15px 0 15px 0;
    text-decoration: none;
    justify-content: center;
    margin: 20px;
    border: none;
    /* font-family: "PT Sans", sans-serif; */
    font-size: 1rem;
    font-weight: 300;
    transition: background-color 0.3s ease;
}

.small-button:hover {
    cursor: pointer;
    background-color: var(--green-800);
}

.small-button > p { 
    margin: 0;
}


/* BACK ARROW */
.back-button {
    /* font-weight: 800;
    border-radius: 10px;
    color: white;
    padding: 0 10px 0 20px;
    align-self: flex-start; */
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sign-up--container .back-button {
    z-index: 100;
}

.back-arrow {
    font-size: 1.8rem;
    color: var(--green-600);
}

.absolute {
    position: absolute;
    top: 30px;
    left: 30px;
}


/* GOOGLE ICONS */

/* When icons are loaded */
.font-loaded .material-symbols-outlined,
.font-loaded .rating-shown,
.font-loaded #star-rating--container {
    visibility: visible;
}

/* When icons are not loaded */
.font-not-loaded .material-symbols-outlined {
    color: transparent !important;
}

.font-not-loaded .navbar-icon--container .material-symbols-outlined,
.font-not-loaded .settings-icon {
    color: transparent !important;
    width: 24px;
    height: 24px;
    border: 2px white solid;
    border-radius: 50%;
    text-align: center;
    align-self: center;
}

.font-not-loaded .tutor-box--profile .material-symbols-outlined {
    color: transparent !important;
    width: 135px;
    height: 135px;
    border: 1.5px var(--green-600) solid;
    border-radius: 50%;
}

.font-not-loaded #tutor-info #tutor-profile {
    color: transparent !important;
    width: 160px;
    height: 160px;
    border: 1.5px var(--green-600) solid;
    border-radius: 50%;
    align-self: center;
    text-align: center;
    margin-top: 40px;
    margin-bottom: -50px;
}

.font-not-loaded .profile-ban {
    border: 1.5px var(--green-600) solid;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 0 11px;
}

.font-not-loaded #filter-icon {
    width: 30px;
    height: 30px;
    border: 1.5px var(--green-600) solid;
}

.font-not-loaded .icon-search-bar,
.font-not-loaded #search-icon {
    visibility: hidden;
}

.font-not-loaded .rating-shown {
    visibility: hidden;
}

.font-not-loaded #star-rating--container {
    visibility: hidden;
}

.font-not-loaded .back-arrow {
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    border-right: 10px solid var(--green-600); 
}

.font-not-loaded .arrow-left {
    width: 0;
    height: 0;
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    border-right: 12px solid var(--green-600); 
    margin-right: 15px;
}

.font-not-loaded .arrow-right {
    width: 0;
    height: 0;
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    border-left: 12px solid var(--green-600); 
    margin-left: 15px;
}

.font-not-loaded .down-arrow {
    color: transparent !important;
    width: 10px;
    height: 10px;
    border-right: 3px solid var(--green-600);
    border-top: 3px solid var(--green-600);
    transform: rotate(45deg);
    margin-top: 10px;
}


/* PAGE INTRO */
#page-intro--container {
    width: 100%;
    height: 100%;
    position: relative;
}

#page-intro {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 0%;
    z-index: -10;
    justify-content: center;
    color: white;
}

.background-image-courses {
    background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/courses/courses-image-1920.webp");
}

.background-image-tutors {
    background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/tutors/tutors-page-1920.webp");
}

.background-image-about {
    background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/about/about-page-1920.webp");
}

.background-image-contact {
    background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/contact/contact-page-1920.webp");
}

#page-intro > h1 {
    font-weight: 200;
    font-size: 3rem;
}

#page-intro > h6 {
    font-size: 1.5rem;
    margin: 0;
    width: 40%;
    text-align: center;
}


/* SEARCH BARS */
.search--container {
    background-color: white;
    width: 620px;
    height: 220px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-bottom: 7px solid var(--green-600);
    padding: 35px 70px 35px 70px; 
    box-sizing: border-box;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icon-search-bar {
    font-size: 1.3rem;
    color: rgba(0, 0, 0, 0.3);
    margin-left: 2px;
}

#search-bar {
    justify-content: space-between;
    border-radius: 100px;
    min-width: 100px;
    max-width: 100%; 
    width: 100%;
    padding: 10px 25px 10px 25px;
    border: 1px solid rgba(0, 0, 0, 0.17);
}

#close-icon:hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.45);
}

#search-bar, 
.select-element {
    box-sizing: border-box;
    height: 60px;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.54) !important;
}

select {
    background-color: white;
    color: black;
}

#search-bar > input {
    border: none;
    font-weight: 400;
    margin: 0;
    color: rgba(0, 0, 0, 0.54);
    flex: 1;
    width: 100%;
}


/* SELECT */
.select-element {
    width: 100%;
    min-width: 145px;
    border-radius: 50px;
    border: none !important;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 30px; /* Add space for custom arrow */
    padding-left: 20px;
    background-image: url("../images/down-arrow-grey.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 16px;
  }
  


/* LOADER */
.loader {
    border: 6px solid #f3f3f3; 
    border-top: 6px solid var(--green-600);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
  }

.loader--container {
    background-color: white;
    z-index: 201;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
}


/* TITLE WITH BACK ARROW */
.title-back--full-title {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.title-back--full-title > hr {
    height: 2px;
    width: 50%;
    border: none;
    background-color: black;
    margin-top: 10px;
    border-radius: 5px;
    margin-bottom: 60px;
    align-self: center;
}

.title-back--full-title > h1 {
    align-self: center;
    font-family: var(--title-font-family);
}

.title-back {
    padding: 18px;
    width: 100%;
    position: fixed;
    background-color: white;
    box-shadow: 0 10px 50px rgba(0,0,0,0.1);
    z-index: 100;
    left: 0;
    top: 0;
}

.no-absolute-transform {
    position: static;
    transform: none;
}

.h1-title {
    font-weight: 400;
    font-size: 1.9rem;
    margin: 0;
}


/* TOAST */
.toast-error {
    background-color: var(--red-600) !important;
}

.toast-error-progress {
    background-color: rgb(228, 143, 140) !important;
}

.toast-success {
    background-color: var(--green-600) !important;
}

.toast-success-progress {
    background-color: rgb(155, 210, 203) !important;
}


/* PAGINATION */
.page-input--container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pagination-arrow {
    color: var(--green-600);
    font-size: 3.5rem;
    cursor: pointer;
    background-color: transparent;
}

.pagination-arrow--disabled {
    cursor: auto;
}

.pagination-arrow:hover,
.pagination-arrow--disabled {
    color: var(--green-800);
}

.pagination--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    z-index: 150;
}

.pagination--p,
.pagination--input {
    margin: 0;
    font-size: 1.2rem;
    font-family: var(--regular-font-family);
}

.pagination--input {
    width: 35px;
    padding: 5px;
    text-align: center;
    margin-right: 5px;
    border: 1.5px var(--green-600) solid;
    border-radius: 2px;
}

/* hide input type number scrollbar */
.pagination--input::-webkit-outer-spin-button,
.pagination--input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pagination--input[type=number] {
  -moz-appearance: textfield;
}


/* MORE INFO DROPDOWN */
.visible {
    visibility: visible;
}

.not-visible {
    visibility: hidden;
}

.dropdown-option {
    width: 150px;
    margin: 0;
    padding: 5px 7px;
}

.dropdown-option:hover {
    cursor: pointer;
    background-color: rgb(0, 172, 151, 0.1);
}

.dropdown--container {
    background-color: white;
    position: absolute;
    right: 0;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.03);
}

.show-more--container {
    position: relative;
}

.show-more-icon {
    cursor: pointer;
}

.red-button {
    background-color: var(--red-600);
    padding: 5px 25px;
    width: fit-content;
    border: none;
    border-radius: 5px;
    color: white;
    margin-top: 30px;
}

.red-button:hover {
    background-color: var(--red-800);
    cursor: pointer;
}


/* PROFILE PICTURE */
.profile-picture {
    border: 1px rgba(0,0,0,0.2) solid;
    border-radius: 50%;
    cursor: pointer;
}

.choose-file-button--container button {
    background-color: var(--green-600);
    color: white;
    border-radius: 4px;
    border: none;
    width: 125px;
    height: 30px;
    cursor: pointer;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
}

.choose-file-button--container button:hover {
    background-color: var(--green-800);
}

.choose-file-button--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.choose-file-button--container p {
    margin: 0;
}

.choose-file--container {
    align-self: flex-start;
}

.select--container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.17);
    border-radius: 50px;
}

.select--container:focus-within {
    border: 1.5px var(--green-600) solid;
    transition: border-color 0.1s ease-in-out;
}

#search-bar:focus-within {
    border: 1.5px var(--green-600) solid;
    transition: border-color 0.1s ease-in-out;
}

.bold-green {
    color: var(--green-600);
    font-weight: 500;
    letter-spacing: 0.7px;
    margin-right: 1.2px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 1280px) {
    .background-image-courses {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/courses/courses-image-1280.webp");
    }

    .background-image-tutors {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/tutors/tutors-page-1280.webp");
    }

    .background-image-about {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/about/about-page-1280.webp");
    }

    .background-image-contact {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/contact/contact-page-1280.webp");
    }
}

@media (max-width: 768px) {
    .background-image-courses {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/courses/courses-image-768.webp");
    }

    .background-image-tutors {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/tutors/tutors-page-768.webp");
    }

    .background-image-about {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/about/about-page-768.webp");
    }

    .background-image-contact {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/contact/contact-page-768.webp");
    }

    .title-back--full-title {
        padding: 0 15px;
    }

    #page-intro {
        height: 70vh;
    }

    #majors-elements a .small-box {
        height: 190px;
        width: 250px;
        box-shadow: none;
    }
}

@media (max-width: 550px) {
    #page-intro {
        padding: 100px 0 50px 0;
        height: 480px;
    }

    #page-intro > h1 {
        margin-bottom: 30px;
        font-size: 2.5rem;
    }
        
    #page-intro > h6 {
        width: 80%;
        font-size: 1.5rem;
    }

    .small-box {
        width: 220px;
        box-shadow: none;
    }

    .select-element {
        height: 45px;
        padding: 10px 15px;
    }

    .element-info-p {
        font-size: 0.8rem;
        margin: 0;
        text-wrap: nowrap;
    }

    #majors-elements a .small-box {
        height: 180px;
        width: 250px;
        box-shadow: none;
    }
    
    #search-bar {
        margin-bottom: 20px;
        height: 50px;
        padding: 10px 15px;
        flex-grow: 1;
    }
    
    .search--container {
        padding: 20px;
        height: fit-content !important;
        width: 100%;
    }
}

@media (max-width: 700px) {
    .search--container {
        width: 90% !important;
    }
}

@media (max-width: 400px) {
    .background-image-courses {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/courses/courses-image-400.webp");
    }

    .background-image-tutors {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/tutors/tutors-page-400.webp");
        background-position: top !important;
    }

    .background-image-about {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/about/about-page-400.webp");
    }

    .background-image-contact {
        background-image: linear-gradient(rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.8)), url("https://studytron.blob.core.windows.net/studytronstatic/contact/contact-page-400.webp");
    }
}

/* TOAST */
@media only screen and (max-width: 480px) {
    .toast-error,
    .toast-success {
        margin: 15px;
        border-radius: 5px;
    }
    
    .Toastify__toast-container {
        top: 0 !important;
    }
}