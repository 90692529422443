#footer--container {
    background-color: rgb(1, 47, 42);
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 25px;
    padding-bottom: 65px;
    margin-top: 100px;
}

.studytron-logo--footer {
    color: white;
    padding-right: 13px;
    margin-right: 13px;
    border-right: 2px white solid;
    box-sizing: content-box;
}

.studytron--container-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.socials-icon {
    color: white;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.socials-icon:hover {
    color: var(--grey-200);
}

.footer-hr {
    height: 2px;
    background-color: rgba(255,255,255,0.9);
    width: 100%;
}

.socials-icons--container,
.footer-socials--container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-socials--container {
    justify-content: space-between;
    width: 100%;
    column-gap: 20px;
}

.socials-icons--container {
    column-gap: 15px;
    width: fit-content;
}

.facebook-icon {
    width: 25px !important;
    height: 25px !important;
}

.footer-website-name {
    color: white;
    margin: 0;
}

.footer-link {
    color: rgba(255,255,255,0.8);
    text-decoration: none;
    border-right: 1px rgba(255,255,255,0.8) solid;
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;
}

.footer-link:hover {
    color: white;
}


@media (max-width: 550px) {
    #footer--container {
        padding-right: 20px;
        padding-left: 20px;
    }

    .footer-link {
        padding: 0 15px;
    }
}

.footer-links--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


@media (max-width: 415px) {
    .footer-link {
        padding: 0 10px;
    }
}